import { Col, Row } from 'react-bootstrap';
import InfoBLock from './InfoBlock';
import AboutBlock from './AboutBlock';
import SkillsBlock from './SkillsBlock';
import { useGetMyselfQuery, useGetPersonAvatarQuery, useUpdateProfileMutation } from '../../app/api/profileApiSlice';
import { useEffect, useState } from 'react';

const LkBLock = () => {
    const { data: myself } = useGetMyselfQuery();
    const { data: avatar } = useGetPersonAvatarQuery(myself?.id, { skip: !myself?.photoUrl });
    const [updateProfile] = useUpdateProfileMutation();

    const [about, setAbout] = useState('');
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        if (myself) {
            setAbout(myself.about);
            setSkills(JSON.parse(myself.skills) ?? []);
        }
    }, [myself]);

    const handleUpdate = () => {
        updateProfile({ about, skills: JSON.stringify(skills) });
    };

    return (
        <div>
            <div className='modal-card mb-4'>
                <div className='modal-card-title mb-4'>
                    <img
                        src={myself?.photoUrl ? avatar?.photo : '/images/icons/person.png'}
                        width={54}
                        height={54}
                        className='me-4 rounded profile-avatar'
                        alt='profile avatar'
                    />
                    {myself?.fio}
                </div>
                <Row className='mb-4'>
                    <Col>
                        <div className='profile-lk-statistic'>
                            <Row>
                                <Col md={3} className='statistic-item'>
                                    <div className='mb-2'>
                                        Рейтинг{' '}
                                        <img src='/images/icons/star-gray.svg' className='ms-2 align-text-top me-1' />
                                        <span className='fw-semibold text-gray'>0,0</span>
                                    </div>
                                    <div className='modal-card-subtitle'>У вас пока нет оценок</div>
                                </Col>
                                <Col md={3} className='statistic-item ps-5'>
                                    <div className='mb-2'>0 работ выполнено</div>
                                    <div>0 отзывов</div>
                                </Col>
                                <Col md={6} className='statistic-item ps-5'>
                                    {myself?.isVerified ? (
                                        <div className='mb-2'>
                                            <img
                                                src='/images/icons/double_mark.svg'
                                                className='ms-2 align-text-top me-1'
                                            />
                                            Профиль подтверждён
                                        </div>
                                    ) : (
                                        <div className='mb-2'>
                                            <img
                                                src='/images/icons/forbidden.svg'
                                                className='ms-2 align-text-top me-1'
                                            />
                                            Профиль не подтверждён
                                        </div>
                                    )}
                                    {myself?.positionIsVerified ? (
                                        <div className='mb-2'>
                                            <img
                                                src='/images/icons/double_mark.svg'
                                                className='ms-2 align-text-top me-1'
                                            />
                                            Документы об образовании подтверждёны
                                        </div>
                                    ) : (
                                        <div className='mb-2'>
                                            <img
                                                src='/images/icons/forbidden.svg'
                                                className='ms-2 align-text-top me-1'
                                            />
                                            Документы об образовании не подтверждёны
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <hr className='mb-4' />
                <InfoBLock myself={myself} />
            </div>
            <AboutBlock about={about} setAbout={setAbout} />
            <SkillsBlock skills={skills} setSkills={setSkills} />
            <button className='btn btn-primary' onClick={handleUpdate}>
                Сохранить
            </button>
        </div>
    );
};

export default LkBLock;
