import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AUTH_SERVER_URL } from '../../services/UrlConstants';
import { DateToString } from '../../helpers/DateHelpers';

const InfoBLock = (props) => {
    const { myself } = props;

    return (
        <Row>
            <Col>
                <Row className='mb-3'>
                    <Col md={3} className='text-label'>
                        Дата рождения
                    </Col>
                    <Col className='text-value'>{DateToString(new Date(myself?.dateOfBirth))}</Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={3} className='text-label'>
                        Пол
                    </Col>
                    <Col className='text-value'>{myself?.gender ? 'мужской' : 'женский'}</Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={3} className='text-label'>
                        Учебное заведение
                    </Col>
                    <Col className='text-value'>{myself?.university}</Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={3} className='text-label'>
                        Специализация
                    </Col>
                    <Col className='text-value'>{myself?.position}</Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={3} className='text-label'>
                        Место работы
                    </Col>
                    <Col className='text-value'>{myself?.company}</Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={3} className='text-label'>
                        Город
                    </Col>
                    <Col className='text-value'>{myself?.city}</Col>
                </Row>
                <Row className='mb-3'>
                    <Col md={3} className='text-label'>
                        Электронная почта
                    </Col>
                    <Col className='text-value'>{myself?.email}</Col>
                </Row>
                <Row>
                    <Col>
                        <Link
                            className='btn btn-outline-primary btn-sm'
                            to={AUTH_SERVER_URL + '/personal/profile'}
                            target='_blank'
                        >
                            Редактировать
                            <img
                                src='/images/icons/edit.svg'
                                className='ms-2 btn-sm btn-outline-primary-icon-filter'
                                width={16}
                            />
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default InfoBLock;
