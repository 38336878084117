import { Col, Row } from 'react-bootstrap';
import { useGetOrderCategoriesQuery } from '../../../app/api/orderApiSlice';
import { useEffect, useState } from 'react';
import CreateServiceModal from './CreateServiceModal';
import { useParams } from 'react-router-dom';
import { useGetServicesQuery } from '../../../app/api/serviceApiSlice';
import ServiceItem from './ServiceItem';

const categoryColors = [
    { id: 1, cls: 'order-category-1' },
    { id: 2, cls: 'order-category-2' },
    { id: 3, cls: 'order-category-3' },
];

export default function ServicesTab(props) {
    const { setNeedZIndex } = props;
    let params = useParams();
    const { workzoneId } = params;

    const { data: categories } = useGetOrderCategoriesQuery();
    const { data: services } = useGetServicesQuery(workzoneId);

    const [isOpenCreateServiceModal, setIsOpenCreateServiceModal] = useState(false);

    useEffect(() => {
        setNeedZIndex(isOpenCreateServiceModal);
    }, [isOpenCreateServiceModal]);

    console.log('services', services);

    return (
        <>
            <CreateServiceModal
                isOpen={isOpenCreateServiceModal}
                closeModal={() => setIsOpenCreateServiceModal(false)}
                workzoneId={workzoneId}
            />
            <div className='modal-card mb-4'>
                <div className='fw-bold mb-3'>Вы можете создать объявление об услуге на следующие виды работ</div>
                {categories?.map((item, index) => (
                    <Row key={item.id}>
                        <Col>
                            <div
                                className={'service-info ' + (index === categories.length - 1 ? 'border-bottom-0' : '')}
                            >
                                <div
                                    className={
                                        'service-info-category ' + categoryColors.find((x) => x.id === item.id).cls
                                    }
                                >
                                    {item.name}
                                    <span className='service-info-count'>
                                        {services?.filter((x) => x.orderType.orderCategoryId === item.id).length}/
                                        {item.orderTypes?.length}
                                    </span>
                                </div>
                                <div>
                                    {item.orderTypes?.map((orderType) => (
                                        <div
                                            key={item.id + '_' + orderType.id}
                                            className={
                                                'service-info-type d-inline-block' +
                                                (services?.some((x) => x.orderType.id === orderType.id)
                                                    ? ' disabled'
                                                    : '')
                                            }
                                        >
                                            {services?.some((x) => x.orderType.id === orderType.id) ? (
                                                <img
                                                    src='/images/icons/double_mark_gray.svg'
                                                    className='me-1 align-text-top'
                                                />
                                            ) : null}
                                            {orderType.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                ))}
                <Row className='mt-2'>
                    <Col>
                        <button
                            className='btn btn-outline-primary btn-sm'
                            onClick={() => setIsOpenCreateServiceModal(true)}
                        >
                            <img src='/images/icons/plus.svg' height={16} className='me-1 align-text-bottom' />
                            Создать услугу
                        </button>
                    </Col>
                </Row>
            </div>
            <div className='modal-card mb-4'>
                <div className='fw-bold mb-3'>Услуги размещённые в каталоге</div>
                <Row>
                    {services
                        ?.filter((x) => x.isVisible)
                        .map((item) => (
                            <div key={item.id} className='col-md-4'>
                                <ServiceItem item={item} setNeedZIndex={setNeedZIndex} />
                            </div>
                        ))}
                </Row>
            </div>
            <div className='modal-card mb-4'>
                <div className='fw-bold mb-3'>Услуги удалённые из каталога</div>
                <Row>
                    {services
                        ?.filter((x) => x.isVisible === false)
                        .map((item) => (
                            <div key={item.id} className='col-md-4'>
                                <ServiceItem item={item} setNeedZIndex={setNeedZIndex} />
                            </div>
                        ))}
                </Row>
            </div>
        </>
    );
}
