import React, { useState } from 'react';
import { Container, Row, Col, ListGroup, Form, Button, Card } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import LayoutMaster from '../../components/layout/LayoutMaster';

const ChatPage = () => {
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    const handleSelectChat = (chat) => {
        setSelectedChat(chat);
        setMessages([
            { id: 1, text: 'Hello!', icon: <FaUserCircle size={30} className="mr-2" /> },
            { id: 2, text: 'Hi, how are you?', icon: <FaUserCircle size={30} className="mr-2" /> }
        ]);
    };

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            setMessages([...messages, { id: messages.length + 1, text: newMessage, icon: <FaUserCircle size={30} className="mr-2" /> }]);
            setNewMessage('');
        }
    };

    return (
        <LayoutMaster>
            <Container className="p-4">
                <Row className="justify-content-center">
                    <Col md={10}>
                        <Card className="shadow-sm">
                            <Row noGutters>
                                <Col md={4} className="border-right">
                                    <ListGroup variant="flush">
                                        <ListGroup.Item action onClick={() => handleSelectChat('Chat 1')}>
                                            <FaUserCircle size={30} className="mr-2" />
                                            Chat 1
                                        </ListGroup.Item>
                                        <ListGroup.Item action onClick={() => handleSelectChat('Chat 2')}>
                                            <FaUserCircle size={30} className="mr-2" />
                                            Chat 2
                                        </ListGroup.Item>
                                        <ListGroup.Item action onClick={() => handleSelectChat('Chat 3')}>
                                            <FaUserCircle size={30} className="mr-2" />
                                            Chat 3
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col md={8} className="d-flex flex-column">
                                    <Card.Header>
                                        <h5>{selectedChat || 'Select a chat'}</h5>
                                    </Card.Header>
                                    <Card.Body className="flex-grow-1 overflow-auto">
                                        {messages.map((message, index) => (
                                            <div key={index} className="d-flex mb-2">
                                                {message.icon}
                                                <div>{message.text}</div>
                                            </div>
                                        ))}
                                    </Card.Body>
                                    {selectedChat && (
                                        <Card.Footer className="d-flex">
                                            <Form.Control
                                                as="textarea"
                                                rows={1}
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                placeholder="Type a message..."
                                            />
                                            <Button onClick={handleSendMessage} className="ml-2">
                                                Send
                                            </Button>
                                        </Card.Footer>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </LayoutMaster>
    );
};

export default ChatPage;