import { apiSlice } from './apiSlice';

export const serviceApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPublicServices: builder.query({
            query: (data) => ({
                url: 'api/services/public?workzoneId=' + (data?.workzoneId ?? ''),
                method: 'GET',
            }),
            providesTags: () => [{ type: 'Services', id: 'List' }],
        }),
        getServices: builder.query({
            query: (workzoneId) => ({
                url: 'api/services?workzoneId=' + workzoneId,
                method: 'GET',
            }),
            providesTags: () => [{ type: 'Services', id: 'List' }],
        }),
        getServiceById: builder.query({
            query: (id) => ({
                url: 'api/services/' + id,
                method: 'GET',
            }),
            providesTags: (result) => [{ type: 'Service', id: result.id }],
        }),
        createService: builder.mutation({
            query: (data) => ({
                url: 'api/services',
                method: 'POST',
                body: { ...data },
            }),
            invalidatesTags: [{ type: 'Services', id: 'List' }],
        }),
        updateService: builder.mutation({
            query: (data) => ({
                url: 'api/services/' + data.id,
                method: 'PUT',
                body: { ...data },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Services', id: 'List' },
                { type: 'Service', id: result.id },
            ],
        }),
        deleteService: builder.mutation({
            query: (id) => ({
                url: 'api/services/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Services', id: 'List' },
                { type: 'Service', id: result.id },
            ],
        }),
    }),
});

export const {
    useGetPublicServicesQuery,
    useGetServicesQuery,
    useGetServiceByIdQuery,
    useLazyGetServiceByIdQuery,
    useCreateServiceMutation,
    useUpdateServiceMutation,
    useDeleteServiceMutation,
} = serviceApiSlice;
