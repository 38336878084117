import { Container } from 'react-bootstrap';
import AboutProfilePanel from './AboutProfilePanel';
import { useParams } from 'react-router-dom';
import { useGetPortfoliosQuery, useGetWorkzoneByIdQuery } from '../../../app/api/workzoneApiSlice';
import AboutCompanyPanel from './AboutCompanyPanel';
import ServicesPanel from './ServicesPanel';
import ProjectPanel from './ProjectPanel';

const InfoPage = () => {
    const params = useParams();
    const { workzoneId } = params;

    const { data: workzone } = useGetWorkzoneByIdQuery(workzoneId);

    console.log('workzone', workzone);

    return (
        <Container className='pt-5 profile-info'>
            {workzone?.personId ? <AboutProfilePanel personId={workzone?.personId} /> : <AboutCompanyPanel />}
            <ServicesPanel workzoneId={workzoneId} />
            <ProjectPanel workzoneId={workzoneId} />
        </Container>
    );
};

export default InfoPage;
