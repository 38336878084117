import Dropzone from 'react-dropzone';
import { useUploadDocMutation } from '../../../app/api/orderApiSlice';

const OrderFileDownloader = (props) => {
    const { infoText, fileType, orderId, accept, maxSize = 10 * 1024 * 1024 } = props;

    const [uploadFile, { isLoading }] = useUploadDocMutation();

    const downloadFile = (acceptedFiles) => {
        var data = new FormData();
        acceptedFiles.forEach((file) => {
            data.append('formFile', file);
        });
        uploadFile({
            orderId,
            typeId: fileType,
            formFile: data,
        });
    };

    const errorHandler = (error) => {
        let errorText = '';
        switch (error.code) {
            case 'file-too-large':
                errorText = `Файл слишком большой. Максимальный размер ${sizeToHuman(maxSize)}.`;
                break;
            case 'file-invalid-type':
                errorText = 'Неверный тип файла.';
                break;
            default:
                errorText = 'Неизвестная ошибка. Проверьте расширение файла, а так же размер.';
                break;
        }
        return <div className='text-danger text-center mt-2'>{errorText}</div>;
    };

    const sizeToHuman = (bytes) => {
        var sizes = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
        if (bytes == 0) {
            return '0 Byte';
        }
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) {
            return bytes + ' ' + sizes[i];
        }
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    };

    return (
        <Dropzone onDrop={(acceptedFiles) => downloadFile(acceptedFiles)} accept={accept} maxSize={maxSize}>
            {({ getRootProps, getInputProps, isDragActive, fileRejections }) => (
                <section>
                    <div {...getRootProps()} className='accordion-dropzone mb-4'>
                        <input {...getInputProps()} />
                        <div className='text-center text-about-file mb-3'>{infoText}</div>
                        <div className='text-center text-download'>
                            <img src='/images/icons/attach.svg' height={16} className='me-2 align-top' />
                            {isLoading ? 'Загрузка...' : isDragActive ? 'Отпустите файл' : 'Загрузка'}
                        </div>
                        {fileRejections.length > 0 && errorHandler(fileRejections[0].errors[0])}
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

export default OrderFileDownloader;
