import { Col, Row } from 'react-bootstrap';
import { useGetPublicServicesQuery } from '../../../app/api/serviceApiSlice';

const categoryColors = [
    { id: 1, cls: 'order-category-1' },
    { id: 2, cls: 'order-category-2' },
    { id: 3, cls: 'order-category-3' },
];

const categoryIcons = [
    { id: 1, icon: 'categoryChirurgiyaIcon.svg' },
    { id: 2, icon: 'categoryOrtopediyaIcon.svg' },
    { id: 3, icon: 'categoryChirurgiyaIcon.svg' },
];

const ServicesPanel = (props) => {
    const { workzoneId } = props;

    const { data: services } = useGetPublicServicesQuery({ workzoneId });

    console.log('services', services);

    return (
        <Row className='mb-4'>
            <Col>
                <div className='card-simple'>
                    <div className='title-block mb-4'>Услуги</div>
                    <Row>
                        {services?.map((service) => (
                            <Col key={service.id} md={4}>
                                <div className='portfolio-item service-card'>
                                    <div className='order-header-type-block d-inline-block me-2'>
                                        <div
                                            className={
                                                'd-inline-block order-category ' +
                                                categoryColors.find((x) => x.id === service.orderType.orderCategoryId)
                                                    .cls
                                            }
                                        >
                                            {service.orderType?.orderCategory?.name}
                                        </div>
                                    </div>
                                    <div className='service-type-block d-flex justify-content-between align-items-center'>
                                        <div className='service-type-name'>{service.orderType.name}</div>
                                        <div>
                                            <img
                                                src={
                                                    '/images/icons/' +
                                                    categoryIcons.find(
                                                        (x) => x.id === service.orderType.orderCategoryId
                                                    ).icon
                                                }
                                                height={32}
                                                className='me-1 align-text-bottom'
                                            />
                                        </div>
                                    </div>
                                    <div className='service-info-block'>
                                        <div className='service-description'>{service.description}</div>
                                        <div className='service-price'>{service.price} ₽</div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default ServicesPanel;
