import { apiSlice } from './apiSlice';

export const workzoneApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMyWorkzones: builder.query({
            query: () => 'api/workzones',
            providesTags: () => [{ type: 'Workzones', id: 'List' }],
        }),
        getWorkzoneById: builder.query({
            query: (id) => 'api/workzones/' + id,
            providesTags: () => [{ type: 'Workzones', id: 'List' }],
        }),
        getMyFavoriteWorkzones: builder.query({
            query: (id) => 'api/workzones/getmyfavorites',
            providesTags: () => [{ type: 'Workzones', id: 'List' }],
        }),
        createWorkzone: builder.mutation({
            query: (data) => ({
                url: 'api/workzones',
                method: 'POST',
                body: { ...data },
            }),
            invalidatesTags: [{ type: 'Workzones', id: 'List' }],
        }),
        getPortfolios: builder.query({
            query: (workzoneId) => 'api/workzones/getportfolios/' + workzoneId,
            providesTags: () => [{ type: 'Projects', id: 'List' }],
        }),
        getPortfolioById: builder.query({
            query: (id) => 'api/workzones/getportfolio/' + id,
            providesTags: (result) => [{ type: 'Project', id: result.id }],
        }),
        createPortfolio: builder.mutation({
            query: (data) => ({
                url: 'api/workzones/createportfolio',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [{ type: 'Projects', id: 'List' }],
        }),
        getPortfolioImage: builder.query({
            query: (id) => 'api/workzones/GetPhotoPortfolios/' + id,
            providesTags: (result) => [{ type: 'Project', id: result.id }],
        }),
        deletePortfolio: builder.mutation({
            query: (id) => ({
                url: 'api/workzones/deleteportfolio/' + id,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Projects', id: 'List' }],
        }),
        updatePortfolio: builder.mutation({
            query: (data) => {
                const params = {};
                for (let pair of data.entries()) {
                    params[pair[0]] = pair[1];
                }
                return {
                    url: 'api/workzones/EditPortfolio/' + params.id,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: (result) => [
                { type: 'Projects', id: 'List' },
                { type: 'Project', id: result.id },
            ],
        }),
    }),
});

export const {
    useGetMyWorkzonesQuery,
    useCreateWorkzoneMutation,
    useGetWorkzoneByIdQuery,
    useGetMyFavoriteWorkzonesQuery,
    useGetPortfoliosQuery,
    useCreatePortfolioMutation,
    useGetPortfolioImageQuery,
    useLazyGetPortfolioImageQuery,
    useDeletePortfolioMutation,
    useUpdatePortfolioMutation,
    useGetPortfolioByIdQuery,
} = workzoneApiSlice;
