import { HubConnectionBuilder } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../app/authSlice';
import { API_SERVER_URL } from '../../services/UrlConstants';
import { useSendChatMessageMutation } from '../../app/api/chatsApiSlice';

const ChatsPage = () => {
    const userId = useSelector(selectUserId);

    const [sendChatMessage] = useSendChatMessageMutation();

    const [connection, setConnection] = useState(null);

    const [message, setMessage] = useState([]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`${API_SERVER_URL}/ws/chats`)
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        }

        loadResourcesAndDataAsync();
    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    connection.invoke('Join', `chats_${userId}`).catch((err) => {
                        console.log(err);
                    });

                    connection.on('ReceiveMessage', (message) => {
                        console.log('Message received: ', message);
                    });
                })
                .catch((e) => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const sendMessage = () => {
        sendChatMessage({ chatId: 1, message });
    };

    return (
        <div>
            <input
                type='text'
                name='message'
                id='message'
                placeholder='Message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={sendMessage}>Send</button>
        </div>
    );
};

export default ChatsPage;
