import { Col, Row } from 'react-bootstrap';
import { useGetPortfoliosQuery, useLazyGetPortfolioImageQuery } from '../../../app/api/workzoneApiSlice';
import { use, useEffect, useState } from 'react';
import ProjectItem from './ProjectItem';

const ProjectPanel = (props) => {
    const { workzoneId } = props;

    const { data: portfolios } = useGetPortfoliosQuery(workzoneId);
    const [getImage] = useLazyGetPortfolioImageQuery();

    const [portfoliosImages, setPortfoliosImages] = useState([]);

    useEffect(() => {
        loadData();
        async function loadData() {
            const images = [];
            portfolios?.map(async (item) => {
                const res = await getImage(item.id).unwrap();
                console.log('res', res);
                images.push({
                    id: item.id,
                    photo: res.photo,
                });
            });
            setPortfoliosImages(images);
        }
        loadData();
    }, [portfolios]);

    console.log('portfoliosImages', portfoliosImages);

    return (
        <Row className='mb-4'>
            <Col>
                <div className='card-simple'>
                    <div className='title-block mb-4'>Услуги</div>
                    <Row>
                        {portfolios?.map((portfolio) => (
                            <Col key={portfolio.id} md={3}>
                                <ProjectItem portfolio={portfolio} />
                            </Col>
                        ))}
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default ProjectPanel;
