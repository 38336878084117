import { Col, Container, Row } from 'react-bootstrap';
import { useGetPublicServicesQuery } from '../app/api/serviceApiSlice';
import { useNavigate } from 'react-router-dom';
import { useLazyGetPersonAvatarQuery } from '../app/api/profileApiSlice';
import { useSelector } from 'react-redux';
import { selectIsAuthorize } from '../app/authSlice';
import { useEffect, useState } from 'react';

const categoryColors = [
    { id: 1, cls: 'order-category-1' },
    { id: 2, cls: 'order-category-2' },
    { id: 3, cls: 'order-category-3' },
];

const categoryIcons = [
    { id: 1, icon: 'categoryChirurgiyaIcon.svg' },
    { id: 2, icon: 'categoryOrtopediyaIcon.svg' },
    { id: 3, icon: 'categoryChirurgiyaIcon.svg' },
];

const IndexPage = () => {
    const navigate = useNavigate();
    const isAuthorize = useSelector(selectIsAuthorize);
    const { data: services } = useGetPublicServicesQuery();
    const [getPersonAvatar] = useLazyGetPersonAvatarQuery();
    const [personAvatars, setPersonAvatars] = useState({});

    const handleClickService = (id) => {
        navigate(`/services/${id}`);
    };

    useEffect(() => {
        loadImages();
        async function loadImages() {
            services?.map(async (service) => {
                let avatar = '/images/icons/person.png';
                if (service?.workzone?.person?.photoUrl) {
                    const res = await getPersonAvatar(service.workzone.person.id).unwrap();
                    avatar = res.photo;
                }
                setPersonAvatars({ ...personAvatars, [service.id]: avatar });
            });
        }
    }, [services]);

    console.log('services', services);
    return (
        <Container className='pt-5 main-page'>
            <Row>
                <Col>
                    <h3 className='mb-4'>Предложения от исполнителей</h3>
                </Col>
            </Row>
            <Row>
                {services?.map((service) => (
                    <Col key={service.id} className='mb-4' md={4}>
                        <div className='portfolio-item service-card' onClick={() => handleClickService(service.id)}>
                            <div className='order-header-type-block d-inline-block me-2'>
                                <div
                                    className={
                                        'd-inline-block order-category ' +
                                        categoryColors.find((x) => x.id === service.orderType.orderCategoryId).cls
                                    }
                                >
                                    {service.orderType?.orderCategory?.name}
                                </div>
                            </div>
                            <div className='service-type-block d-flex justify-content-between align-items-center'>
                                <div className='service-type-name'>{service.orderType.name}</div>
                                <div>
                                    <img
                                        src={
                                            '/images/icons/' +
                                            categoryIcons.find((x) => x.id === service.orderType.orderCategoryId).icon
                                        }
                                        height={32}
                                        className='me-1 align-text-bottom'
                                    />
                                </div>
                            </div>
                            {isAuthorize ? (
                                <div className='service-info-block'>
                                    <div className='service-description'>{service.description}</div>
                                    <div className='service-price'>{service.price} ₽</div>
                                    <div className='service-owner d-flex align-items-center gap-3'>
                                        <div className='owner-avatar'>
                                            <img
                                                src={personAvatars[service.id]}
                                                width={40}
                                                height={40}
                                                alt='profile avatar'
                                                className='profile-avatar '
                                            />
                                        </div>
                                        <div className='owner-about'>
                                            <div className='owner-name mb-1'>{service?.workzone?.person?.fio}</div>
                                            <div className='owner-rating'>нет оценок</div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                'Нет доступа'
                            )}
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default IndexPage;
