import { useEffect, useState } from 'react';
import { useDeletePortfolioMutation, useGetPortfolioImageQuery } from '../../app/api/workzoneApiSlice';
import DeletePortfolioModal from './DetelePortfolioModal';
import AddPortfolioModal from './AddPortfolioModal';

const PortfolioItem = (props) => {
    const { item, setNeedZIndex } = props;
    const { data: image } = useGetPortfolioImageQuery(item.id);

    const [deletePortfolio] = useDeletePortfolioMutation();

    const [isOpenDeletePortfolioModal, setIsOpenDeletePortfolioModal] = useState(false);
    const [isOpenAddPortfolioModal, setIsOpenAddPortfolioModal] = useState(false);

    useEffect(() => {
        setNeedZIndex(isOpenDeletePortfolioModal || isOpenAddPortfolioModal);
    }, [isOpenDeletePortfolioModal, isOpenAddPortfolioModal]);

    const handleDeletePortfolio = async () => {
        await deletePortfolio(item.id);
        setIsOpenDeletePortfolioModal(false);
    };

    return (
        <>
            <AddPortfolioModal
                isOpen={isOpenAddPortfolioModal}
                closeModal={() => setIsOpenAddPortfolioModal(false)}
                portfolioId={item.id}
            />
            <DeletePortfolioModal
                isOpen={isOpenDeletePortfolioModal}
                closeModal={() => setIsOpenDeletePortfolioModal(false)}
                deleteHandle={handleDeletePortfolio}
            />
            <div className='portfolio-item'>
                <img src={image?.photo} className='img-fluid' />
                <div className='portfolio-name'>{item.name}</div>
                <div className='d-flex'>
                    <button className='btn flex-fill' onClick={() => setIsOpenAddPortfolioModal(true)}>
                        <img src='/images/icons/edit.svg' height={16} className='me-1 align-text-bottom' />
                        Редактировать
                    </button>
                    <button className='btn flex-fill' onClick={() => setIsOpenDeletePortfolioModal(true)}>
                        <img src='/images/icons/trash.svg' height={16} className='me-1 align-text-bottom' />
                        Удалить
                    </button>
                </div>
            </div>
        </>
    );
};

export default PortfolioItem;
