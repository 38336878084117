import { Button, Modal } from 'react-bootstrap';

const DeletePortfolioModal = (props) => {
    const { isOpen, closeModal, deleteHandle } = props;

    const handleClose = () => closeModal();

    return (
        <Modal show={isOpen} onHide={handleClose} backdrop='static' size='md' className='modal-delete' centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className='text-center'>Вы уверены, что хотите удалить портфолио?</Modal.Body>
            <Modal.Footer className='justify-content-center'>
                <Button variant='primary' onClick={handleClose} className='m-0 me-4'>
                    Отмена
                </Button>
                <Button variant='outline-primary' onClick={deleteHandle} className='m-0'>
                    Удалить
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeletePortfolioModal;
