import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useGetOrderCategoriesQuery } from '../../../app/api/orderApiSlice';
import { useEffect, useState } from 'react';
import OptionalServiceBlock from './OptionalServiceBlock';
import {
    useCreateServiceMutation,
    useGetServicesQuery,
    useLazyGetServiceByIdQuery,
    useUpdateServiceMutation,
} from '../../../app/api/serviceApiSlice';

const labelMb = 4;
const inputMb = 12 - labelMb;

const CreateServiceModal = (props) => {
    const { isOpen, closeModal, serviceId, workzoneId } = props;
    const { data } = useGetOrderCategoriesQuery();
    const [createService] = useCreateServiceMutation();
    const [updateService] = useUpdateServiceMutation();
    const [getServiceById, { data: service }] = useLazyGetServiceByIdQuery();
    const { data: extendedServices } = useGetServicesQuery(workzoneId);

    const [categoryId, setCategoryId] = useState();
    const [typeId, setTypeId] = useState();
    const [description, setDescription] = useState('');
    const [scopeWork, setScopeWork] = useState('');
    const [diagnostics, setDiagnostics] = useState('');
    const [cost, setCost] = useState(0);
    const [duration, setDuration] = useState(0);

    const [services, setServices] = useState([{ name: '', cost: 0, duration: 1, isDuration: false, id: 0 }]);

    useEffect(() => {
        loadData();
        async function loadData() {
            if (serviceId !== undefined) {
                const service = await getServiceById(serviceId).unwrap();
                if (service) {
                    setDescription(service.description);
                    setScopeWork(service.scopeWork);
                    setDiagnostics(service.diagnosticsNeeded);
                    setCost(service.price);
                    setDuration(service.duration);
                    setServices(service.serviceAdditionals?.map((x) => ({ ...x, cost: x.price })));
                    setCategoryId(service.orderType.orderCategory.id);
                    setTypeId(service.orderType.id);
                }
            }
        }
    }, [serviceId]);

    useEffect(() => {
        if (data?.length > 0 && categoryId === undefined) {
            setCategoryId(data[0].id);
        }
    }, [data]);

    useEffect(() => {
        if (!data?.find((x) => x.id === parseInt(categoryId, 10))?.orderTypes.some((x) => x.id === typeId)) {
            setTypeId(data?.find((x) => x.id === parseInt(categoryId, 10))?.orderTypes[0].id);
        }
    }, [categoryId]);

    const addService = () => {
        setServices((prev) => [...prev, { name: '', cost: 0, duration: 1, isDuration: false, id: 0 }]);
    };

    const editService = (index, field, value) => {
        setServices((prev) => {
            const newServices = [...prev];
            newServices[index][field] = value;
            return newServices;
        });
    };

    const handleCreateService = async () => {
        const newService = {
            workzoneId,
            orderTypeId: parseInt(typeId, 10),
            description,
            scopeWork,
            diagnosticsNeeded: diagnostics,
            price: cost,
            duration,
            isVisible: true,
            serviceAdditionals: services
                .filter((x) => x.name !== '')
                .map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                        price: item.cost,
                        duration: item.duration,
                        isDuration: item.isDuration,
                    };
                }),
        };
        if (serviceId !== undefined) {
            newService.id = serviceId;
            await updateService(newService).unwrap();
        } else {
            await createService(newService).unwrap();
        }
        closeModal();
    };

    return (
        <Modal show={isOpen} onHide={closeModal} backdrop='static' size='lg' className='add-details-modal' centered>
            <Modal.Header closeButton>
                <Modal.Title>Создание услуги</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className='mb-3' controlId='formCategory'>
                    <Form.Label column md={labelMb}>
                        Категория
                    </Form.Label>
                    <Col md={inputMb}>
                        <Form.Select value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
                            {data?.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3' controlId='formType'>
                    <Form.Label column md={labelMb}>
                        Вид работы
                    </Form.Label>
                    <Col md={inputMb}>
                        <Form.Select value={typeId} onChange={(e) => setTypeId(e.target.value)}>
                            {data
                                ?.find((x) => x.id === parseInt(categoryId, 10))
                                ?.orderTypes?.map((item) => (
                                    <option
                                        key={item.id}
                                        value={item.id}
                                        disabled={extendedServices
                                            ?.filter((x) => x.orderType.id !== service?.orderType.id)
                                            .some((x) => x.orderType.id === item.id)}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                        </Form.Select>
                        {extendedServices
                            ?.filter((x) => x.orderType.id !== service?.orderType.id)
                            .some((x) => x.orderType.id === parseInt(typeId, 10)) && (
                            <div className='text-danger font-size-13'>
                                Выбранный вид работ уже существует. Выберите другой
                            </div>
                        )}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3' controlId='formDescription'>
                    <Form.Label column md={labelMb}>
                        Описание задания
                    </Form.Label>
                    <Col md={inputMb}>
                        <Form.Control
                            as='textarea'
                            rows={5}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3' controlId='formVolume'>
                    <Form.Label column md={labelMb}>
                        Объём выполняемых работ
                    </Form.Label>
                    <Col md={inputMb}>
                        <Form.Control
                            as='textarea'
                            rows={5}
                            value={scopeWork}
                            onChange={(e) => setScopeWork(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3' controlId='formDiagnostics'>
                    <Form.Label column md={labelMb}>
                        Необходимые диагностики
                    </Form.Label>
                    <Col md={inputMb}>
                        <Form.Control
                            as='textarea'
                            rows={5}
                            value={diagnostics}
                            onChange={(e) => setDiagnostics(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3' controlId='formCost'>
                    <Form.Label column md={labelMb}>
                        Стоимость (руб.)
                    </Form.Label>
                    <Col md='3'>
                        <Form.Control type='number' value={cost} onChange={(e) => setCost(e.target.value)} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3' controlId='formDuration'>
                    <Form.Label column md={labelMb}>
                        Срок выполнения (дней)
                    </Form.Label>
                    <Col md='3'>
                        <Form.Control type='number' value={duration} onChange={(e) => setDuration(e.target.value)} />
                    </Col>
                </Form.Group>
                <hr />
                <div className='fw-bold mb-2'>Дополнительные услуги</div>
                <div className='text-label mb-3'>К основной услуге вы можете добавить несколько дополнительных.</div>
                {services?.map((item, index) => (
                    <OptionalServiceBlock key={index} editService={editService} index={index} {...item} />
                ))}
                <div className='text-end'>
                    <button className='btn btn-only-text-primary p-0' onClick={addService}>
                        <img src='/images/icons/plus.svg' height={16} className='me-1' /> Добавить ещё услугу
                    </button>
                </div>
                <Row>
                    <Col>
                        <Button
                            variant='primary'
                            onClick={handleCreateService}
                            disabled={extendedServices
                                ?.filter((x) => x.orderType.id !== service?.orderType.id)
                                .some((x) => x.orderType.id === typeId)}
                        >
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default CreateServiceModal;
