const AboutBlock = (props) => {
    const { about, setAbout } = props;
    return (
        <div className='modal-card mb-4'>
            <div className='fw-bold mb-3'>О себе</div>
            <textarea
                className='form-control'
                rows={5}
                placeholder='Напишите что-нибудь о себе'
                value={about}
                onChange={(e) => setAbout(e.target.value)}
            ></textarea>
        </div>
    );
};

export default AboutBlock;
