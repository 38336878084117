import { useGetPortfolioImageQuery } from '../../../app/api/workzoneApiSlice';

const ProjectItem = (props) => {
    const { portfolio } = props;
    const { data: image } = useGetPortfolioImageQuery(portfolio.id);

    console.log('image', image);
    return (
        <div className='portfolio-item'>
            <img src={image?.photo} className='img-fluid' />
            <div className='portfolio-name'>{portfolio?.name}</div>
        </div>
    );
};

export default ProjectItem;
