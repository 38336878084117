import { apiSlice } from './apiSlice';

export const chtsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        sendChatMessage: builder.mutation({
            query: (data) => ({
                url: 'api/messages/sendMessage',
                method: 'POST',
                body: { ...data },
            }),
            invalidatesTags: (result) => [{ type: 'ChatMessage', id: result.id }],
        }),
    }),
});

export const { useSendChatMessageMutation } = chtsApiSlice;
