import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDeleteServiceMutation, useUpdateServiceMutation } from '../../../app/api/serviceApiSlice';
import DeleteServiceModal from './DeleteServiceModal';
import CreateServiceModal from './CreateServiceModal';

const categoryColors = [
    { id: 1, cls: 'order-category-1' },
    { id: 2, cls: 'order-category-2' },
    { id: 3, cls: 'order-category-3' },
];

const categoryIcons = [
    { id: 1, icon: 'categoryChirurgiyaIcon.svg' },
    { id: 2, icon: 'categoryOrtopediyaIcon.svg' },
    { id: 3, icon: 'categoryChirurgiyaIcon.svg' },
];

const ServiceItem = (props) => {
    const { item, setNeedZIndex } = props;

    const [deleteService] = useDeleteServiceMutation();
    const [updateService] = useUpdateServiceMutation();

    const [isOpenAddServiceModal, setIsOpenAddServiceModal] = useState(false);
    const [isOpenDeleteServiceModal, setIsOpenDeleteServiceModal] = useState(false);

    useEffect(() => {
        setNeedZIndex(isOpenDeleteServiceModal || isOpenAddServiceModal);
    }, [isOpenDeleteServiceModal, isOpenAddServiceModal]);

    const handleDeleteService = async () => {
        await deleteService(item.id);
        setIsOpenDeleteServiceModal(false);
    };

    const handleCheckVisible = (e) => {
        updateService({ ...item, isVisible: e.target.checked });
    };

    return (
        <>
            <DeleteServiceModal
                isOpen={isOpenDeleteServiceModal}
                closeModal={() => setIsOpenDeleteServiceModal(false)}
                deleteHandle={handleDeleteService}
            />
            <CreateServiceModal
                isOpen={isOpenAddServiceModal}
                closeModal={() => setIsOpenAddServiceModal(false)}
                serviceId={item.id}
                workzoneId={item.workzoneId}
            />
            <div className='portfolio-item service-card'>
                <div className='order-header-type-block d-inline-block me-2'>
                    <div
                        className={
                            'd-inline-block order-category ' +
                            categoryColors.find((x) => x.id === item.orderType.orderCategoryId).cls
                        }
                    >
                        {item.orderType?.orderCategory?.name}
                    </div>
                </div>
                <div className='service-type-block d-flex justify-content-between align-items-center'>
                    <div className='service-type-name'>{item.orderType.name}</div>
                    <div>
                        <img
                            src={
                                '/images/icons/' +
                                categoryIcons.find((x) => x.id === item.orderType.orderCategoryId).icon
                            }
                            height={32}
                            className='me-1 align-text-bottom'
                        />
                    </div>
                </div>
                <hr className='hr my-0' />
                <div className='service-description'>{item.description}</div>
                <div className='service-price'>{item.price} ₽</div>
                <div className='d-flex btn-block'>
                    <button className='btn flex-fill' onClick={() => setIsOpenAddServiceModal(true)}>
                        <img src='/images/icons/edit.svg' height={16} className='me-1 align-text-bottom' />
                        Редактировать
                    </button>
                    <button className='btn flex-fill' onClick={() => setIsOpenDeleteServiceModal(true)}>
                        <img src='/images/icons/trash.svg' height={16} className='me-1 align-text-bottom' />
                        Удалить
                    </button>
                </div>
                <div className='show-in-catalog-block d-flex justify-content-center'>
                    <Form.Check
                        type='switch'
                        id='showInCatalog'
                        label='Разместить в каталоге'
                        className='text-label mb-0'
                        checked={item.isVisible}
                        onChange={handleCheckVisible}
                    />
                </div>
            </div>
        </>
    );
};

export default ServiceItem;
