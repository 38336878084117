import { Col, Form, Row } from 'react-bootstrap';

const labelMb = 4;
const inputMb = 12 - labelMb;

const OptionalServiceBlock = (props) => {
    const { editService, index, name, cost, duration, isDuration } = props;

    const setName = (value) => editService(index, 'name', value);
    const setCost = (value) => editService(index, 'cost', value);
    const setDuration = (value) => editService(index, 'duration', value);
    const setIsDuration = (value) => editService(index, 'isDuration', value);

    return (
        <div>
            <Form.Group as={Row} className='mb-3' controlId='formName'>
                <Form.Label column md={labelMb}>
                    Название услуги
                </Form.Label>
                <Col md={inputMb}>
                    <Form.Control type='text' value={name} onChange={(e) => setName(e.target.value)} />
                </Col>
            </Form.Group>
            <Row className='mb-3'>
                <Col md={{ span: 5, offset: labelMb }}>
                    <Form.Check
                        label='Эта диагностика увеличивает время выполнения задачи'
                        className='text-label'
                        onChange={(e) => setIsDuration(e.target.checked)}
                        checked={isDuration}
                        type='checkbox'
                        id='isDuration'
                    />
                </Col>
                {isDuration && (
                    <Col md={3} className='text-label d-flex align-items-center gap-2'>
                        на <Form.Control type='number' value={duration} onChange={(e) => setDuration(e.target.value)} />{' '}
                        дней
                    </Col>
                )}
            </Row>
            <Form.Group as={Row} className='mb-4' controlId='formName'>
                <Form.Label column md={labelMb}>
                    Стоимость (руб.)
                </Form.Label>
                <Col md={3}>
                    <Form.Control type='number' value={cost} onChange={(e) => setCost(e.target.value)} />
                </Col>
            </Form.Group>
        </div>
    );
};

export default OptionalServiceBlock;
