import { Col, Row } from 'react-bootstrap';
import { useGetPersonAvatarQuery, useGetPublicInfoQuery } from '../../../app/api/profileApiSlice';
import { DateToString } from '../../../helpers/DateHelpers';

const AboutProfilePanel = (props) => {
    const { personId } = props;

    const { data: person } = useGetPublicInfoQuery(personId);
    const { data: avatar } = useGetPersonAvatarQuery(personId, { skip: !person?.photoUrl });

    return (
        <Row className='mb-4'>
            <Col>
                <div className='card-simple'>
                    <Row>
                        <Col md={2}>
                            <Row className='mb-4'>
                                <Col>
                                    <img
                                        src={person?.photoUrl ? avatar?.photo : '/images/icons/person.png'}
                                        className='rounded profile-avatar img-fluid'
                                        alt='profile avatar'
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='profile-rating-block d-flex align-items-center justify-content-between'>
                                        Рейтинг{' '}
                                        <div>
                                            <img
                                                src='/images/icons/star-gray.svg'
                                                className='ms-2 align-text-top me-1'
                                            />
                                            <span className='fw-semibold text-gray rating-number'>0,0</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col>
                                    <div className='profile-fio'>{person?.fio}</div>
                                </Col>
                            </Row>
                            <Row className='mb-4'>
                                <Col>
                                    <Row className='mb-3'>
                                        <Col md={4} className='text-label'>
                                            Дата рождения
                                        </Col>
                                        <Col className='text-value'>{DateToString(new Date(person?.dateOfBirth))}</Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col md={4} className='text-label'>
                                            Пол
                                        </Col>
                                        <Col className='text-value'>{person?.gender ? 'мужской' : 'женский'}</Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col md={4} className='text-label'>
                                            Учебное заведение
                                        </Col>
                                        <Col className='text-value'>{person?.university}</Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col md={4} className='text-label'>
                                            Специализация
                                        </Col>
                                        <Col className='text-value'>{person?.position}</Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col md={4} className='text-label'>
                                            Место работы
                                        </Col>
                                        <Col className='text-value'>{person?.company}</Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col md={4} className='text-label'>
                                            Город
                                        </Col>
                                        <Col className='text-value'>{person?.city}</Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col md={4} className='text-label'>
                                            Электронная почта
                                        </Col>
                                        <Col className='text-value'>{person?.email}</Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='block-label'>Обо мне</div>
                                    <div className='profile-about'>{person?.about}</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col>
                                    <div className='profile-accepted-info'>
                                        {person?.isVerified ? (
                                            <div className='mb-2'>
                                                <img
                                                    src='/images/icons/double_mark.svg'
                                                    className='ms-2 align-text-top me-1'
                                                />
                                                Профиль подтверждён
                                            </div>
                                        ) : (
                                            <div className='mb-2'>
                                                <img
                                                    src='/images/icons/forbidden.svg'
                                                    className='ms-2 align-text-top me-1'
                                                />
                                                Профиль не подтверждён
                                            </div>
                                        )}
                                        {person?.positionIsVerified ? (
                                            <div className='mb-2'>
                                                <img
                                                    src='/images/icons/double_mark.svg'
                                                    className='ms-2 align-text-top me-1'
                                                />
                                                Документы об образовании подтверждёны
                                            </div>
                                        ) : (
                                            <div>
                                                <img
                                                    src='/images/icons/forbidden.svg'
                                                    className='ms-2 align-text-top me-1'
                                                />
                                                Документы об образовании не подтверждёны
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='block-label'>Навыки</div>
                                    <div>
                                        {person?.skills &&
                                            JSON.parse(person?.skills).map((s, i) => (
                                                <div className='profile-skill' key={i}>
                                                    {s}
                                                </div>
                                            ))}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='d-grid gap-2'>
                                        <button className='btn btn-primary' type='button'>
                                            Написать сообщение
                                        </button>
                                        <button className='btn btn-primary' type='button'>
                                            Предложить задание
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default AboutProfilePanel;
