import { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ProfileMenu from './ProfileMenu';
import LkBLock from './LkBlock';
import PortfolioTab from './PortfolioTab';
import ServicesTab from './ServicesTab/ServicesTab';

const ProfileModal = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(0);
    const [needZIndex, setNeedZIndex] = useState(false);

    const handleClose = () => navigate(`/workzone/${params.workzoneId}/plane`);

    return (
        <Modal
            show={true}
            onHide={handleClose}
            backdrop='static'
            size='xl'
            className='create-order-modal profile-modal'
            style={needZIndex ? { zIndex: 9 } : null}
        >
            <Modal.Header closeButton>
                <Modal.Title>Профиль</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProfileMenu activeTab={activeTab} setActiveTab={setActiveTab} />
                {activeTab === 0 && <LkBLock />}
                {activeTab === 1 && <PortfolioTab setNeedZIndex={setNeedZIndex} />}
                {activeTab === 2 && <ServicesTab setNeedZIndex={setNeedZIndex} />}
            </Modal.Body>
        </Modal>
    );
};

export default ProfileModal;
