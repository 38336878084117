import { useState } from 'react';

const skillsArray = [
    '3Shape',
    'Exocad',
    'Exoplan',
    'Maestro 3D',
    'RealGuide',
    'Invivo',
    'Blender 4 Dental',
    'coDiagnostix',
    'Dolphin',
    'Blue Sky Plan',
    'PlastyCAD',
    '3Diagnosys',
    'Radiant',
    'SmileDesigner',
    'Materialise Magic',
    'OnDemand',
    'Inlab',
    'Хирургия',
    'Ортодонтия',
    'Гнатология',
    'Ортопедия',
    'Моделирование',
    'Планирование',
    'Диагностика',
    'План лечения',
    'Фрезевка',
    '3D печать',
];
const SkillsBlock = (props) => {
    const { skills, setSkills } = props;

    const handleSkillClick = (skill) => {
        setSkills([...skills, skill]);
    };

    const handleRemoveSkill = (skill) => {
        setSkills(skills.filter((item) => item !== skill));
    };

    const handleClearAll = () => {
        setSkills([]);
    };

    return (
        <div className='modal-card mb-4'>
            <div className='fw-bold mb-3'>Навыки</div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className='d-flex justify-content-between'>
                        <div className='text-label mb-2'>Вы выбрали</div>
                        <button className='btn btn-sm-only-text p-0' onClick={handleClearAll}>
                            Удалить все
                        </button>
                    </div>
                    <div>
                        {skills?.length === 0 ? (
                            <span className='text-gray fst-italic'>Выберите навыки из списка ниже</span>
                        ) : null}
                        {skills?.map((item, index) => (
                            <div className='skill-block' key={index}>
                                {item}{' '}
                                <button className='btn btn-sm-only-text p-0' onClick={() => handleRemoveSkill(item)}>
                                    <img src='/images/icons/cross-gray.svg' className='align-text-top' />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className='text-label mb-2'>Все навыки</div>
                    <div>
                        {skillsArray
                            .filter((item) => !skills?.includes(item))
                            .map((item, index) => (
                                <button className='btn btn-skill' onClick={() => handleSkillClick(item)} key={index}>
                                    {item}
                                </button>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkillsBlock;
