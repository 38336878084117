import { apiSlice } from './apiSlice';

export const profileApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAuthProfile: builder.query({
            query: () => 'api/persons/GetAuthInfo',
            providesTags: (result) => [{ type: 'Profile', id: result?.email }],
        }),
        getMyself: builder.query({
            query: () => 'api/persons/GetMyself',
            providesTags: (result) => [{ type: 'Profile', id: result?.email }],
        }),
        getPersonAvatar: builder.query({
            query: (personId) => 'api/persons/GetPersonAvatar?personId=' + personId,
        }),
        getPublicInfo: builder.query({
            query: (personId) => 'api/persons/GetPublicInfo?personId=' + personId,
        }),
        updateProfile: builder.mutation({
            query: (data) => ({
                url: 'api/persons/EditPersonInfo',
                method: 'PUT',
                body: { ...data },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Profiles', id: 'List' },
                { type: 'Profile', id: result.id },
            ],
        }),
    }),
});

export const {
    useGetAuthProfileQuery,
    useLazyGetAuthProfileQuery,
    useGetMyselfQuery,
    useGetPersonAvatarQuery,
    useGetPublicInfoQuery,
    useLazyGetPersonAvatarQuery,
    useUpdateProfileMutation,
} = profileApiSlice;
