import { Col, Row } from 'react-bootstrap';

const AboutCompanyPanel = () => {
    return (
        <Row>
            <Col>
                <div className='card-simple'>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={6}></Col>
                        <Col md={4}></Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default AboutCompanyPanel;
