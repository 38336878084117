import { Col, Row } from 'react-bootstrap';

const ProfileMenu = (props) => {
    const { activeTab, setActiveTab } = props;
    return (
        <Row>
            <Col>
                <div className='menu-list mb-4'>
                    <div className='d-inline-block menu-block'>
                        <button className={'btn' + (activeTab === 0 ? ' active' : '')} onClick={() => setActiveTab(0)}>
                            Личные данные
                        </button>
                    </div>
                    <div className='d-inline-block menu-block'>
                        <button className={'btn' + (activeTab === 1 ? ' active' : '')} onClick={() => setActiveTab(1)}>
                            Портфолио
                        </button>
                    </div>
                    <div className='d-inline-block menu-block'>
                        <button className={'btn' + (activeTab === 2 ? ' active' : '')} onClick={() => setActiveTab(2)}>
                            Услуги
                        </button>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default ProfileMenu;
