import { useParams } from 'react-router-dom';
import { useGetPortfoliosQuery } from '../../app/api/workzoneApiSlice';
import AddPortfolioModal from './AddPortfolioModal';
import { useEffect, useState } from 'react';
import PortfolioItem from './PortfolioItem';

const PortfolioTab = (props) => {
    const { setNeedZIndex } = props;
    let params = useParams();
    const { workzoneId } = params;

    const { data: portfolios } = useGetPortfoliosQuery(workzoneId);

    const [isOpenAddPortfolioModal, setIsOpenAddPortfolioModal] = useState(false);

    useEffect(() => {
        setNeedZIndex(isOpenAddPortfolioModal);
    }, [isOpenAddPortfolioModal]);

    return (
        <>
            <AddPortfolioModal isOpen={isOpenAddPortfolioModal} closeModal={() => setIsOpenAddPortfolioModal(false)} />
            <div>
                <div className='mb-4'>
                    <button className='btn btn-outline-primary btn-sm' onClick={() => setIsOpenAddPortfolioModal(true)}>
                        <img src='/images/icons/plus.svg' height={16} className='me-1 align-text-bottom' />
                        Добавить проект
                    </button>
                </div>
                <div className='modal-card mb-4'>
                    <div className='fw-bold mb-3'>Загруженные проекты</div>
                    <div className='row'>
                        {portfolios?.length === 0 ? (
                            <span className='text-gray fst-italic'>Нет проектов</span>
                        ) : (
                            portfolios?.map((item) => (
                                <div className='col-md-4' key={item.id}>
                                    <PortfolioItem item={item} setNeedZIndex={setNeedZIndex} />
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PortfolioTab;
