import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import {
    useCreatePortfolioMutation,
    useGetPortfolioByIdQuery,
    useUpdatePortfolioMutation,
} from '../../app/api/workzoneApiSlice';
import { useParams } from 'react-router-dom';

const AddPortfolioModal = (props) => {
    const { isOpen, closeModal, portfolioId } = props;
    let params = useParams();
    const { workzoneId } = params;

    const [addPortfolio, { isLoading }] = useCreatePortfolioMutation();
    const [editPortfolio] = useUpdatePortfolioMutation();
    const { data: portfolio } = useGetPortfolioByIdQuery(portfolioId, { skip: !portfolioId });

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [formFile, setFormFile] = useState(null);
    const [fileError, setFileError] = useState(null);

    useEffect(() => {
        if (portfolio) {
            setName(portfolio.name);
            setDescription(portfolio.description);
        }
    }, [portfolio]);

    const handleClose = () => closeModal();

    const downloadFile = (acceptedFiles) => {
        setFormFile(acceptedFiles);
    };

    const errorHandler = (error) => {
        let errorText = '';
        switch (error.code) {
            case 'file-too-large':
                errorText = `Файл слишком большой. Максимальный размер 200 КБ.`;
                break;
            case 'file-invalid-type':
                errorText = 'Неверный тип файла.';
                break;
            default:
                errorText = 'Неизвестная ошибка. Проверьте расширение файла, а так же размер.';
                break;
        }
        return <div className='text-danger text-center mt-2'>{errorText}</div>;
    };

    const handleAddPortfolio = () => {
        if (formFile === null && portfolioId === null) {
            setFileError('Файл не выбран.');
            return;
        }
        var data = new FormData();
        formFile?.forEach((file) => {
            data.append('file', file);
        });
        data.append('workzoneId', workzoneId);
        data.append('name', name);
        data.append('description', description);
        if (portfolioId !== null && portfolio !== undefined) {
            data.append('id', portfolioId);
            data.append('photoUrl', portfolio.photoUrl);
            editPortfolio(data);
        } else {
            addPortfolio(data);
        }
        handleClose();
    };

    return (
        <Modal show={isOpen} onHide={handleClose} backdrop='static' size='md' className='add-details-modal' centered>
            <Modal.Header closeButton>
                <Modal.Title>Добавление проекта</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='mb-3'>
                    <Col>
                        <Form.Label className='mb-2'>Название кейса</Form.Label>
                        <Form.Control type='text' value={name} onChange={(e) => setName(e.target.value)} />
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col>
                        <Form.Label className='mb-2'>Описание кейса</Form.Label>
                        <Form.Control
                            as='textarea'
                            rows={5}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label className='mb-2'>Загрузка</Form.Label>
                        <div className='simple-file-upload mb-4'>
                            <Dropzone
                                onDrop={(acceptedFiles) => downloadFile(acceptedFiles)}
                                accept={{ 'image/jpeg': ['.jpg', '.png'] }}
                                maxSize={200 * 1024}
                            >
                                {({ getRootProps, getInputProps, isDragActive, fileRejections }) => (
                                    <section>
                                        <div {...getRootProps()} className='accordion-dropzone'>
                                            <input {...getInputProps()} />
                                            <div className='text-center text-about-file mb-3'>
                                                Файлы могут быть в формате: .jpg, .png. Максимальный размер 200 КБ.
                                            </div>
                                            <div className='text-center text-download'>
                                                <img
                                                    src='/images/icons/attach.svg'
                                                    height={16}
                                                    className='me-2 align-top'
                                                />
                                                {formFile
                                                    ? 'Файл готов к загрузке'
                                                    : isDragActive
                                                    ? 'Отпустите файл'
                                                    : 'Загрузить'}
                                            </div>
                                            {fileRejections.length > 0 && errorHandler(fileRejections[0].errors[0])}
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            {fileError && <div className='text-danger text-center mt-2'>{fileError}</div>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant='primary' onClick={handleAddPortfolio}>
                            Сохранить
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default AddPortfolioModal;
